* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  /* font-family: sans-serif; */
}

html {
  scroll-behavior: smooth;
}
