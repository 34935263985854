#header {
  height: 100vh;
  position: relative;
  color: white;
  background: linear-gradient(rgba(0, 0, 0, 0.281), rgba(255, 255, 255, 0)),
    url("https://firebasestorage.googleapis.com/v0/b/agroeira-5616b.appspot.com/o/agroeirawallpaper.jpg?alt=media&token=ae1151a8-c577-4ab6-9601-1bec76631b4a");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

#header .svg-logo {
  fill: black;
}

#header .content {
  z-index: 4;
}

#header .content .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header .content .btns .btn {
  padding: 0.5rem 1.2rem;
  border-radius: 2rem;
  margin: 0 0.5rem;
  color: white;
}

#header .content .btns .btn-mint {
  border: 1px solid white;
}
#header .content .btns .btn-connect {
  border: none;
  background: #f04225;
}

#header .content .centerText {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#header .content .centerText .title {
  font-size: clamp(4rem, 5vw, 106px);
  line-height: 10rem;
}

#header .content .centerText .desc {
  text-transform: uppercase;
  font-size: 24px;
  margin-top: -1rem;
}

#header .content .vertical-line {
  height: 20%;
  width: 3px;
  border: none;
  margin-bottom: -10rem;
  color: white;
  background-color: white;
  background: white;
  opacity: 0.8;
}

@media only screen and (max-width: 900px) {
  #header .content .vertical-line {
    height: 22rem;
    width: 3px;
    margin-bottom: -10rem;
  }

  #header .content .centerText .title {
    line-height: 5rem;
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 330px) {
  #header .content .centerText .title {
    font-size: clamp(4rem, 5vw, 6rem);
  }
  #header .content .vertical-line {
    height: 15rem;
    width: 3px;
    margin-bottom: 6rem;
  }
}
